import { render, staticRenderFns } from "./Combobox.vue?vue&type=template&id=60dbc132&scoped=true&"
import script from "./Combobox.vue?vue&type=script&lang=js&"
export * from "./Combobox.vue?vue&type=script&lang=js&"
import style0 from "./Combobox.vue?vue&type=style&index=0&id=60dbc132&scoped=true&lang=css&"
import style1 from "./Combobox.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60dbc132",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VCombobox,VIcon,VListItem,VListItemAction,VListItemContent,VListItemTitle})
