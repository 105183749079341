<template>
  <v-dialog v-model="dialog" persistent max-width="1200px" content-class="no-overflow">
    <v-card class="segoeui">
      <!-- Header -->
      <v-card-title class="d-flex justify-space-between modal-header">
        <span class="title segoeui-grey">{{ voteEditId && !isView ? 'Редактирование голосования' : 'Создание голосования' }}</span>
        <v-btn icon @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <!-- Body -->
      <v-card-text class="modal-body">
        <v-container class="mt-4 pa-0">
          <v-form ref="form">
						<div class="block-info mb-4">
							<span class="segoeui-grey" v-if="!isView">1. Основная информация</span>
							<span class="segoeui-grey" v-else>Предварительный просмотр</span>
						</div>
            <v-row>
              <v-col cols="4">
                <span v-if="!isView" class="segoeui-dark ft-16">Адрес</span>
                <span v-else class="segoeui-grey ft-14">Адрес</span>
								<p v-if="isView" class="text-dark">{{house.apartment_address}}</p>
								<v-autocomplete
									v-else
									ref="autocompleteMenu"
									v-model="house"
									placeholder="Выберите адрес"
									class="border-all mt-1"
									solo
									dense
									hide-details
									flat
									return-object
									:items="houses"
									item-text="apartment_address"
									item-value="id"
									append-icon=""
									required
									:disabled="voteEditId && (modalData.status.sys_name === 'active' || modalData.status.sys_name === 'done')"
								>
									<template v-slot:append-outer>
										<img 
											src="@/assets/angle-down.svg" 
											alt="Custom Icon"
											class="mt-1 mr-2"
											style="width: 14px; height: 10px; cursor: pointer;"
											@click="toggleMenu"
										>
									</template>
								</v-autocomplete>
							</v-col>
              <v-col cols="4">
								<span v-if="!isView" class="segoeui-dark ft-16">Начало голосования</span>
								<span v-else class="segoeui-grey ft-14">Начало голосования</span>
								<p v-if="isView" class="text-dark">{{ dateBeginView | timestamp(true) }}</p>
								<div v-else class="d-flex mt-1">
									<span style="flex: 7;">
										<DatePickerPlain
											:date.sync="date_begin"
											:allowPastDates="false"
											:classes="'br-left'"
											:disabled="voteEditId && (modalData.status.sys_name === 'active' || modalData.status.sys_name === 'done')"
										/>
								</span>
								<span style="flex: 3;">
									<v-text-field 
										v-model="startTime"
										placeholder="00:00" 
										v-mask="'##:##'"
										required
										class="border-all br-right"
										solo
										dense
										hide-details
										flat
										:disabled="voteEditId && (modalData.status.sys_name === 'active' || modalData.status.sys_name === 'done')"
										>
								</v-text-field>
								</span>
								</div>
              </v-col>
              <v-col cols="4">
								<span v-if="!isView" class="segoeui-dark ft-16">Завершение голосования</span>
								<span v-else class="segoeui-grey ft-14">Завершение голосования</span>
								<p v-if="isView" class="text-dark">{{ dateEndView | timestamp(true) }}</p>
								<div v-else class="d-flex mt-1">
									<span style="flex: 7;">
										<DatePickerPlain
											:date.sync="date_end"
											:allowPastDates="false"
											:classes="'br-left'"
										/>
									</span>
								<span style="flex: 3;">
									<v-text-field 
										v-model="endTime"
										placeholder="00:00" 
										v-mask="'##:##'"
										required
										class="border-all br-right"
										solo
										dense
										hide-details
										flat
										>
								</v-text-field>
								</span>
								</div>
								
              </v-col>
              <v-col cols="8">
								<span v-if="!isView" class="segoeui-dark ft-16">Ответственный за голосование</span>
								<span v-else class="segoeui-grey ft-14">Ответственный за голосование</span>
                <v-text-field 
									v-if="!isView"
									v-model="responsible_voting"
									placeholder="Введите ФИО" 
									required
									class="border-all mt-1"
									solo
									dense
									hide-details
									flat
									item-text="apartment_address"
									item-value="id"
									@keydown.enter="addResponsible"
									append-icon="mdi-plus"
									@click:append="addResponsible"
									:disabled="voteEditId && (modalData.status.sys_name === 'active' || modalData.status.sys_name === 'done')"
									>
								</v-text-field>
								<v-row>
									<v-col v-for="(name, index) in responsibles" :key="index" cols="auto">
										<v-chip
											class="mt-3 custom-chip segoeui-grey"
											:disabled="voteEditId && (modalData.status.sys_name === 'active' || modalData.status.sys_name === 'done')"
										>
											{{ name }}
											<v-btn v-if="!isView" @click="removeResponsible(index)" small icon plain color="red">
												<v-icon right style="font-size: 20px;">mdi-close</v-icon>
											</v-btn>
										</v-chip>
									</v-col>
								</v-row>
              </v-col>
              <v-col cols="4">
								<span v-if="!isView" class="segoeui-dark ft-16">РКА</span>
								<span v-else class="segoeui-grey ft-14">РКА</span>
								<p v-if="isView" class="text-dark">{{ house.rca }}</p>
                <v-text-field 
									v-else
									v-model="house.rca"
									placeholder="Генерируется автоматически" 
									disabled 
									class="border-all mt-1"
									solo
									dense
									hide-details
									flat
									item-text="apartment_address"
									item-value="id"
									>
								</v-text-field>
              </v-col>
              <v-col cols="12">
								<span v-if="!isView" class="segoeui-dark ft-16">Категория помещений</span>
								<span v-else class="segoeui-grey ft-14">Категория помещений</span>
                <ChipsGroupMultiple
									:chips="catalog.room_types"
									:activeChipID.sync="data.room_types"
									:activeChip.sync="roomTypeIndex"
									:borderAll="false"
									:inModal="true"
									:disabled="isView || voteEditId && (modalData.status.sys_name === 'active' || modalData.status.sys_name === 'done')"
									:showArrow="catalog.room_types.length > 4"
								/>
              </v-col>
            </v-row>

          </v-form>

					<div v-if="!isView" class="block-info mt-3">
						<span class="segoeui-grey">2. Вопросы</span>
					</div>

					<!-- Questions -->
					<div class="block-question mt-4" :class="{'disabled-question': voteEditId && (modalData.status.sys_name === 'active' || modalData.status.sys_name === 'done')}" v-for="(item, i) in !isView ? questions : questionsView">
						<span class="ml-1 ft-16 segoeui-dark">Вопрос - {{ i + 1 }}</span>
						<p class="ml-1 text-dark" v-if="isView">
							{{ item.description }}
						</p>
						<v-textarea
							v-else
							v-model="item.description" 
							placeholder="Описание вопроса"
							auto-grow
							rows="3"
							class="desc mt-1 ft-14"
							outlined
							dense
							no-resize
							flat
							maxlength="700"
  						:counter="700"
							:disabled="voteEditId && (modalData.status.sys_name === 'active' || modalData.status.sys_name === 'done')"
							>
						</v-textarea>
						<div class="variants mt-4 ml-4">
							<span class="ml-2 segoeui-dark ft-16">Варианты ответов</span>
							<template v-if="isView">
								<v-radio-group
									column
									class="ma-0"
									hide-details
								>
									<v-radio
										v-for="(option, index) in item.options"
										:key="index"
										class="segoeui-grey view-radio rad"
										readonly
										disabled
										:label="option.option"
									></v-radio>
								</v-radio-group>
								<!-- <p v-for="(option, index) in item.options" :key="index">{{ option.option }}</p> -->
							</template>
							<v-radio-group v-else v-model="item.defaultOptions" :mandatory="false" row :disabled="voteEditId && (modalData.status.sys_name === 'active' || modalData.status.sys_name === 'done')">
								<v-row class="ml-2">
									<v-col cols="12" class="pa-0">
										<v-radio label="За / Против /  Воздержусь" :value="true" @click="item.options = [{option: ''}]" class="rad segoeui-grey"></v-radio>
									</v-col>
									<v-col cols="12" class="pa-0 pt-1">
										<v-radio label="Другой вариант:" :value="false" class="rad"></v-radio>
										<v-row class="mt-3 ml-5">
											<v-col cols="7" class="pt-0 pb-0" v-for="(answer, index) in item.options" :key="index">
												<div v-if="index === 0" class="d-flex align-center mb-1">
													<v-text-field
														v-model="answer.option"
														class="desc"
														placeholder="Вариант ответа..."
														dense
														outlined
														flat
														hide-details
														maxlength="200"
														:disabled="item.defaultOptions || voteEditId && (modalData.status.sys_name === 'active' || modalData.status.sys_name === 'done')">
													</v-text-field>
													<v-btn icon color="primary" class="ml-3" :class="{'pointer-none': !answer.option}" outlined rounded @click="addOtherAnswer(i)" :disabled="item.defaultOptions || voteEditId && (modalData.status.sys_name === 'active' || modalData.status.sys_name === 'done')">
														<v-icon>mdi-plus</v-icon>
													</v-btn>
												</div>
												<div v-else-if="item.options.length > 1" class="d-flex align-center ft-14 segoeui-grey">
													<span>• {{ answer.option }}</span>
													<v-btn icon plain @click="removeAnswer(i, index)">
														<v-icon class="remove-option-icon">mdi-close</v-icon>
													</v-btn>
												</div>
											</v-col>
										</v-row>	
									</v-col>
								</v-row>
							</v-radio-group>
						</div>
						<v-btn v-if="i > 0 && !isView" icon plain color="primary" class="remove-question" @click="removeQuestion(i)" :disabled="voteEditId && (modalData.status.sys_name === 'active' || modalData.status.sys_name === 'done')">
							<v-icon>mdi-close</v-icon>
						</v-btn>
						<v-row v-if="!isView" class="pt-2">
							<v-col v-if="!isView" cols="auto">
								<file-input
									v-model="selectedFiles"
									@change="handleEditFileChange($event, i)"
									:disabled="voteEditId && (modalData.status.sys_name === 'active' || modalData.status.sys_name === 'done')"
								></file-input>
							</v-col>
							<v-col v-for="(file, ind) in item.files" :key="ind" cols="auto" class="position-relative image-container">
								<div v-if="file.type.includes('image')" class="image-wrapper">
									<img :src="file.url" class="notify-image">
									<v-btn
										@click="deleteLocalFile('image', ind, file.id, i)"
										icon
										color="red"
										class="delete-btn"
										:disabled="voteEditId && (modalData.status.sys_name === 'active' || modalData.status.sys_name === 'done')"
									>
										<v-icon>mdi-close</v-icon>
									</v-btn>
								</div>
								<div v-else class="image-wrapper">
									<img src="@/assets/file.svg" class="notify-file">
									<p class="ma-0">
										<a @click="downloadFile(file)" class="file-name ft-8 segoeui-lightgrey">{{ truncatedName(file.original_name ? file.original_name : file.name) }}</a>
									</p>
									<v-btn
										@click="deleteLocalFile(file.type, ind, file.id, i)"
										icon
										color="red"
										class="delete-btn"
										:disabled="voteEditId && (modalData.status.sys_name === 'active' || modalData.status.sys_name === 'done')"
									>
										<v-icon>mdi-close</v-icon>
									</v-btn>
								</div>
							</v-col>
						</v-row>
						<v-row v-else class="mt-2">
							<v-col v-for="(file, ind) in item.files" :key="ind" cols="auto" class="position-relative image-container">
								<div v-if="file.type.includes('image')" class="image-wrapper">
									<img :src="file.url" class="notify-image">
								</div>
								<div v-else class="image-wrapper">
									<img src="@/assets/file.svg" class="notify-image">
									<p class="ma-0">
										<a @click="downloadFile(file)" class="ft-8 segoeui-lightgrey">{{ truncatedName(file.name) }}</a>
									</p>
								</div>
							</v-col>
						</v-row>
						<div v-if="!isView && questions.length === i+1" class="add-btn">
							<v-btn outlined color="primary" class="text-none button-add-question" rounded @click="addQuestion" :disabled="voteEditId && (modalData.status.sys_name === 'active' || modalData.status.sys_name === 'done')">
								<v-icon left style="font-size: 20px;">mdi-plus</v-icon> Добавить вопрос
							</v-btn>
						</div>
					</div>

					<div v-if="isView" class="block-info mt-3">
						<span>Помещения участвующие в голосовании</span>
					</div>
					<v-row v-if="isView" class="mb-6 mt-4">
						<v-col cols="12" md="6" v-for="(item, i) in gdbrnInfo" :key="i">
							<v-expansion-panels class="px-7">
								<v-expansion-panel class="gdbrn-info">
									<v-expansion-panel-header class="segoeui-dark ft-16">
										Всего {{ item.sys_name === 'apartment' ? 'квартир' : item.sys_name === 'non-residential-premises' ? 'НП' : 'паркомест'  }} 
										<v-spacer></v-spacer>
										<span class="amount-rooms">{{ item.gbdrn_room_count }}</span>
									</v-expansion-panel-header>
									<v-expansion-panel-content>
										<v-data-table
											class="overflow-x gbdrn-table mb-2"
											height="100%"
											item-key="id"
											:loading="loading"
											hide-default-footer
											:headers="headers"
											:server-items-length="10"
											:items="item.gdbrn_rooms"
										>
										<template v-slot:top>
											<v-toolbar flat style="background: #F1F1F1;">
												<v-row>
													<v-col cols="10" class="align-center d-flex px-0 pl-2">
														<v-text-field
															v-model="addingRooms[i]"
															class="border-all room"
															solo
															dense
															rounded
															flat
															hide-details
															placeholder="Добавить номера 1-10 либо 1, 8, 33..."
														>
														</v-text-field>
													</v-col>
													<v-col cols="2" class="px-0 d-flex justify-center">
														<v-btn icon outlined color="white" class="primary" small @click="addRooms(item, i)">
															<v-icon>mdi-plus</v-icon>
														</v-btn>
													</v-col>
												</v-row>
												
											</v-toolbar>
										</template>
										<template v-slot:[`header.number`]>
											<span class="segoeui-dark text-table-center">№ пом</span>
										</template>
										<template v-slot:[`header.rca`]>
											<span class="segoeui-dark text-table-center">РКА</span>
										</template>
										<template v-slot:[`item.number`]="{ item }">
											<td class="d-flex justify-center align-center">
												<span class="font-weight-bold segoeui-dark">{{item.number}}</span>
											</td>
										</template>
										<template v-slot:[`item.action`]="{ item }">
											<td class="d-flex justify-end align-center">
												<div class="center">
													<v-btn v-if="item.rca" @click="deleteVoteRoom(item, i)" text style="color: #8C9197;">
														<v-icon>mdi-close</v-icon>
													</v-btn>
													<v-btn v-else text @click="editVoteRoom(item, i)" :class="{'ok-btn-grey': item.rcaNew ? item.rcaNew.length < 16 : true}">
														OK
													</v-btn>
												</div>
											</td>
										</template>
										<template v-slot:[`item.rca`]="{ item }">
											<td class="d-flex justify-center align-center">
												<span v-if="item.rca">{{ item.rca }}</span>
												<span v-else>
													<v-text-field
														v-model="item.rcaNew"
														class="border-all rca"
														v-mask="'################'"
														solo
														dense
														rounded
														flat
														hide-details
														placeholder="Введите номер РКА"
														:error="String(item.rcaNew).length > 0 && String(item.rcaNew).length < 16"
													>
													</v-text-field>
												</span>
											</td>
										</template>

										</v-data-table>
									</v-expansion-panel-content>
								</v-expansion-panel>
							</v-expansion-panels>
						</v-col>
					</v-row>
        </v-container>
				<v-row v-if="alert" class="mx-5 mt-7">
					<v-col cols="12" class="py-0">
						<v-alert
							class="mb-0"
							:type="alert.color"
							:color="alert.color"
							outlined
							dense
						>
							{{ alert.message }}
						</v-alert>
					</v-col>
				</v-row>
				<!-- Actions -->
				<v-card-actions v-if="!isView" class="pb-4 mt-7">
					<v-spacer></v-spacer>
					<v-btn v-if="voteEditId && (modalData.status.sys_name === 'active' || modalData.status.sys_name === 'done')" class="text-none mr-4 px-3 segoeui" color="primary" :loading="isSaving" @click="create('save')">Сохранить изменения</v-btn>
					<v-btn v-else class="text-none mr-4 px-3" color="primary" :loading="isSaving" outlined @click="create('save')">Сохранить</v-btn>
					<v-btn v-if="(voteEditId && modalData.status.sys_name === 'draft') || !voteEditId" color="primary" class="text-none px-3" :loading="isLoading" @click="create">Создать</v-btn>
				</v-card-actions>
				<v-card-actions v-else class="pb-4 mt-7">
					<v-spacer></v-spacer>
					<v-btn class="text-none mr-4 px-3" color="primary" outlined @click="backEdit">Редактировать</v-btn>
					<v-btn color="primary" class="text-none px-3" @click="publish">Опубликовать</v-btn>
				</v-card-actions>
				</v-card-text>
    </v-card>
		<v-dialog v-model="confirmModal" persistent width="350">
			<v-card>
				<v-card-text class="border-bottom py-5 text-center">
					<slot name="body">
						{{ textModal }}
						<p class="ma-0 black--text">{{timeModal}}</p>
					</slot>
				</v-card-text>
				<v-card-actions class="d-flex justify-center">
					<v-btn color="primary" depressed @click="closeModal('update'); confirmModal = false">
						ОК
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Label from "@/components/app/Label.vue";
import FileInput from '@/components/app/FileInputV2.vue';
import { timestampToDdmmyyyy } from "@/helpers/helpers";
import ChipsGroupMultiple from "@/components/app/ChipsGroupMultiple";
import DatePickerPlain from "@/components/app/DatePickerPlain";
import { watch } from "vue";
export default {
  name: 'VoteNewTableModal',
	components: {
		Label,
		FileInput,
		ChipsGroupMultiple,
		DatePickerPlain
	},
	props: {
		modalData: {
			type: Object,
			default: () => ({}),
		},
	},
  data() {
    return {
			alert: null,
      dialog: true,
			isView: false,
			loading: false,
			confirmModal: false,
			voteId: null,
			voteEditId: null,
			isLoading: false,
			isSaving: false,
			house: {
				id: '',
				rca: ''
			},
			addingRooms: [],
			responsible_voting: '',
			responsibles: [],
			startTime: '00:00',
			endTime: '00:00',
			dateBeginView: '',
			date_begin: '',
			dateEndView: '',
			date_end: '',
			textModal: '',
			timeModal: '',
      data: {
        house_id: '',
        date_begin: '',
        date_end: '',
        responsible_voting: '',
				rca: '',
				room_types: []
      },
			gdbrnInfo: [],
			filterRooms: [],
			roomTypeIndex: [],
			variants: null,
			selectedFiles: [],
			questionFiles: {},
			files: [],
			images: [],
			imagesInfo: [],
			deletedFiles: [],
			questionsView: [],
			questions_copy: [],
			questionsIndexes: [],
			headers: [
				{
					text: "№ пом",
					value: "number",
					sortable: false,
				},
				{
					text: "РКА",
					value: "rca",
					sortable: false,
				},
				{
					text: "",
					value: "action",
					sortable: false,
				},
			],
			questions: [
				{
					question_files: [],
					files: [],
					description: '',
					height: 420,
					defaultOptions: true,
					options: [{
						option: ''
					}]
				}
			],
      categories: ['Квартира', 'Нежилое помещение', 'Паркинг', 'Кладовка'],
    };
  },
	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			base_role: "auth/GET_USER_ROLE",
			userId: "auth/GET_USER_ID",
			kskId: "auth/GET_KSK_ID",
			houses: "ksk/GET_HOUSES",
		}),
	},
  methods: {
		toggleMenu() {
			this.$refs.autocompleteMenu.isFocused = !this.$refs.autocompleteMenu.isFocused;
			this.$refs.autocompleteMenu.$refs.menu.isActive = !this.$refs.autocompleteMenu.$refs.menu.isActive;
		},
		truncatedName(name) {
      const maxLength = 12;
      return name.length > maxLength ? name.substring(0, maxLength) + '...' : name;
    },
		async deleteVoteRoom(item, index) {
			try {
				this.loading = true
				await this.$api.ksk.delete_vote_room(item.id)
				this.loadGdbrnInfo(item.type._id, index)
			} catch (error) {
				this.$root.snackbar.show({ color: "red", message: "Произошла ошибка с удалением помещения" });
				this.loading = false
			}
		},
		async editVoteRoom(item, index) {
			try {
				this.loading = true
				const data = {
					_method: "patch",
					rca: item.rcaNew
				}
				await this.$api.ksk.edit_vote_room(data, item.id)
				this.loadGdbrnInfo(item.type._id, index)
			} catch (error) {
				this.$root.snackbar.show({ color: "red", message: "Произошла ошибка" });
				this.loading = false
			}
		},
		addRooms(item, index) {
			if(!this.addingRooms[index]) return
			this.loading = true
			const rooms = this.addingRooms[index].split(',').map(room => room.trim());
			rooms.forEach(room => {
				if (room.includes('-')) {
					const [start, end] = room.split('-').map(str => str.trim());
					this.addRoomRange(item, start, end, index);
				} else {
					this.addSingleRoom(item, room, index);
				}
			});

			this.addingRooms[index] = '';
		},

		addRoomRange(item, start, end, index) {
			this.$api.ksk.add_rooms(
				{
					first_number: start,
					last_number: end,
					type_id: item._id
				},
				this.voteId
			)
			.then(() => {
				this.loadGdbrnInfo(item._id, index)
			})
			.catch(err => {
				console.error('Ошибка добавления диапазона комнат:', err);
				this.$root.snackbar.show({ color: "red", message: err.message || "Произошла ошибка с удалением помещения" });
				this.loading = false
			});
		},

		addSingleRoom(item, roomNumber, index) {
			console.log('itemitemitemitemitem', item)
			this.$api.ksk.add_room(
				{
					number: roomNumber,
					type_id: item._id
				},
				this.voteId
			)
			.then(() => {
				this.loadGdbrnInfo(item._id, index)
			})
			.catch(err => {
				console.error('Ошибка добавления комнаты:', err);
				this.$root.snackbar.show({ color: "red", message: err.message || "Произошла ошибка с удалением помещения" });
				this.loading = false
			});
		},
		addResponsible() {
      if (this.responsible_voting.trim() !== '') {
        this.responsibles.push(this.responsible_voting.trim());
        this.responsible_voting = '';
      }
    },
		removeResponsible(index) {
      this.responsibles.splice(index, 1);
    },
		filterVoted(status, index) {
			console.log('e', status)
			console.log('index', index)
			this.loadGdbrnInfo(this.gdbrnInfo[index]._id, index, status)
		},
		filteredRooms(item, index) {
			console.log('itee', item)
      const filter = this.filterRooms[index] || '';
      return item.gdbrn_rooms.filter(room => {
        return room.number.toString().includes(filter);
      });
    },
		timestampToDdmmyyyy,
		backEdit() {
			this.questions = this.questions_copy
			this.isView = false
		},
    closeModal(status) {
      this.dialog = false;
      this.$emit('close-modal');
			if(status === 'update') {
				this.$emit('update-info');
			}
    },
		addQuestion() {
			this.questions.push({
				question_files: [],
				files: [],
				description: '',
				height: 420,
				defaultOptions: true,
				options: [{
					option: ''
				}]
			})
		},
		async removeQuestion(i) {
			console.log('this.questions[i]', this.questions[i])
			if (this.questions[i] && this.questions[i].id) {
				await this.$api.ksk.delete_vote_question_v3(this.questions[i].id);
			}
			this.questions.splice(i, 1);
		},
    removeAnswer(i, index) {
      this.questions[i].height = this.questions[i].height - 80
			this.questions[i].options.splice(index, 1)
    },
    addOtherAnswer(i) {
      this.questions[i].height = this.questions[i].height + 80
			this.questions[i].options.unshift({
				option: ''
			})
    },
    async create(event) {
			if(event !== 'save') {
				if (!this.customValidation()) return;
				this.isLoading = true
			} else {
				if (!this.house.apartment_address) {
					this.alert = {
						color: "error",
						message: 'Выберите адрес',
					};
					return 
				}
				this.isSaving = true
			}
			console.log('QQQQQ CHECK', this.questions)
			// FILES
			if(this.voteId || this.voteEditId) {
				this.deletedFiles.forEach(el => {
					this.deleteFile(el);
				});
			}
			this.questions_copy = JSON.parse(JSON.stringify(this.questions));
			for (let i = 0; i < this.selectedFiles.length; i++) {
				const { file, questionIndex } = this.selectedFiles[i];
				const formData = new FormData();
				formData.append('file', file);

				try {
					const res = await this.$api.ksk.load_files(formData);
					const fileId = res.id;
					this.deletedFiles.push(res.id)
					this.questions[questionIndex].question_files.push(fileId);
				} catch (error) {
					this.isLoading = false
					this.isSaving = false
					console.error('Error uploading file:', error);
					this.alert = {
						color: "error",
						message: error.message.response.data.message || "Не удалось загрузить файл",
					};
					this.isCreating = false;
					return;
				}
			}
			console.log('this.data.questions!!!!', this.data.questions)
			console.log('this.questions where id', this.questions)
			if (this.questions.length >= 1 && this.questions[0].description) {
				this.data.questions = [...this.questions];
				console.log('this.data.questions!', this.data.questions)
				this.data.questions.forEach(question => {
					console.log("question!!!", question)
					delete question.height;
					if (question.defaultOptions) {
						delete question.options;
					} else {
						question.options = question.options
							.map(option => option.option.trim())
							.filter(option => option !== '');
					}
				});
			}
			this.data.house_id = this.house.id
			this.data.rca = this.house.rca
			this.data.responsible_voting = this.responsibles
			if (this.date_begin) {
					this.data.date_begin = this.formatToUTC(this.date_begin, this.startTime);
			} else {
					delete this.data.date_begin;
			}

			if (this.date_end) {
					this.data.date_end = this.formatToUTC(this.date_end, this.endTime);
			} else {
					delete this.data.date_end;
			}
			this.data.company_id = this.kskId
			this.data.is_draft = true
			console.log('data', this.data)
			try {
				let response;
				if(!this.voteId && !this.voteEditId) {
					const data = JSON.parse(JSON.stringify(this.data));
					if(data.questions) {
						data.questions.forEach((question) => {
							delete question.files
						});
					}
					response = await this.$api.ksk.add_vote_v3(data);
				} else {
					let data = JSON.parse(JSON.stringify(this.data));
					if(this.voteEditId && (this.modalData.status.sys_name === 'active' || this.modalData.status.sys_name === 'done')) {
						data = {
							date_end: this.data.date_end,
							_method: 'patch'
						}
					} else {
						data._method = 'patch'
						data.questions.forEach((question, index) => {
							delete question.files
							this.deletedFiles.forEach(el => {
								console.log('ELL', el)
								console.log('question.question_files', question.question_files)
								question.question_files = question.question_files.filter(e => e.id !== el);
								console.log('question.question_files 2', question.question_files)
							});
							if (this.questionsIndexes[index]) {
								question.id = this.questionsIndexes[index];
							}
						});
					}
					const id = this.voteEditId ? this.voteEditId : this.voteId
					response = await this.$api.ksk.edit_vote_v3(data, id);
				}
				console.log('response', response);
				this.voteId = response.id
				this.responsibles = response.responsible_voting.map(voting => voting.name);
				this.house = response.house
				this.dateBeginView = response.date_begin
				this.dateEndView = response.date_end
				this.questionsView = response.questions
				this.filterRooms = []
				this.gdbrnInfo = []
				response.room_types.forEach(el => {
					this.loadGdbrnInfo(el._id)
				});
				console.log('this.questions', this.questions)
				console.log('this.questions_copy', this.questions_copy)
				console.log('this.questionsView', this.questionsView)
				if(this.questionsView.length) {
					for (let i = 0; i < this.questions.length; i++) {
						this.questionsView[i].question_files = this.questions_copy[i].question_files
						this.questionsView[i].files = this.questions[i].files
						console.log('this.questionsView[i]', this.questionsView[i])
						this.questionsIndexes[i] = this.questionsView[i].id
					}
				}
				console.log('this.data.questions', this.data.questions)
				console.log('this.questionsView', this.questionsView)
				this.isLoading = false
				this.isSaving = false
				if(event === 'save') {
					this.closeModal('update')
				} else {
					this.isView = true
					this.alert = null
				}
			} catch (error) {
				this.isLoading = false
				this.isSaving = false
				this.alert = {
					color: "error",
					message: error.message || "Заполните все выделенные поля",
				};
				console.log('err', error)
			}
    },
		async uploadFiles(file, questionIndex) {
			try {
				let data = { file: file };
				const formData = data.toFormData();
				const res = await this.$api.ksk.load_files(formData);
				this.files.push(res.id);

				this.questions[questionIndex].question_files.push({ id: res.id, name: file.name });
				console.log('this.questions', this.questions);
			} catch (error) {
				console.log('err', error);
				this.alert = {
					color: "error",
					message: error.message || "Неподдерживаемый файл",
				};
			}
		},
		handleEditFileChange(event, questionIndex) {
			console.log('event', event);
			console.log('selectedFiles', this.selectedFiles);

			if (!event.length) {
				this.questions[questionIndex].question_files = [];
				this.selectedFiles = [];
				this.$set(this.questionFiles, questionIndex, []);
				return;
			}

			if (!this.questionFiles[questionIndex]) {
				this.$set(this.questionFiles, questionIndex, []);
			}

			const currentFilesCount = this.questions[questionIndex].files.length;
      const newFilesCount = event.length;
      if (currentFilesCount + newFilesCount > 10) {
        alert("Вы можете прикрепить максимум 10 файлов.");
        return;
      }
			if(currentFilesCount + newFilesCount > 7) {
				this.questions[questionIndex].height = 520
			}
			event.forEach((file) => {
				this.selectedFiles.push({ file, questionIndex });
			});

			this.selectedFiles.forEach(({ file, questionIndex }) => {
				console.log('file', file);
				const imageExists = this.images.some(image => image.name === file.name);
				const fileExists = this.questions[questionIndex].files.some(existingFile => existingFile.name === file.name);

				if (!imageExists && !fileExists) {
					if (file.type.includes('image')) {
						const reader = new FileReader();
						reader.onload = (e) => {
							this.$set(this.questions[questionIndex].files, this.questions[questionIndex].files.length, { url: e.target.result, name: file.name, type: file.type });
						};
						reader.readAsDataURL(file);
					} else {
						this.$set(this.questions[questionIndex].files, this.questions[questionIndex].files.length, { name: file.name, size: file.size, type: file.type });
					}
				} else {
					console.log('Duplicate file not added:', file.name);
				}
			});
		},
		deleteLocalFile(type, index, id, indexQuestion) {
			console.log('id', id)
			if(id) this.deletedFiles.push(id)
			this.questions[indexQuestion].files.splice(index, 1);
			this.selectedFiles = this.selectedFiles.filter(fileObj => {
				!(fileObj.name === this.questions[indexQuestion].files[index]?.name && fileObj.questionIndex === indexQuestion);
			});
		},
		async deleteFile(id) {
			try {
				await this.$api.ksk.delete_files(id)
			} catch (error) {
				console.error('Ошибка при удалении изображения:', error);
				this.alert = {
					color: 'error',
					message: 'Ошибка при удалении изображения:' + error,
				};
			}
		},
		async publish() {
			if(this.voteId) {
				const data = {
					_method: 'patch',
					is_draft: false,
				}
				await this.$api.ksk.edit_vote_v3(data, this.voteId);
				this.textModal = `Голосование будет опубликовано`
				this.timeModal = `${this.$options.filters.timestamp(this.dateBeginView)}`
				this.confirmModal = true
			} else {
				this.closeModal('update')
			}
		},
		async loadGdbrnInfo(type_id, index, voteStatus) {
			const params = {
				type_id: type_id
			}
			if(index && voteStatus !== 'clear') {
				params.voted = voteStatus
			}
			const res = await this.$api.ksk.load_gdbrn_info(this.voteId, params);
			if(index !== undefined && index !== null) {
				this.gdbrnInfo = this.gdbrnInfo.map((item, i) => (i === index ? res : item));
			} else {
				this.gdbrnInfo.push(res)
			}
			this.loading = false
			console.log('gdbrnInfo', this.gdbrnInfo)
		},
		customValidation() {
			let message;
			const timeFormat = /^([01]\d|2[0-3]):([0-5]\d)$/;
			if (!this.house.apartment_address) {
				message = "Выберите адрес";
			} else if (!this.house.rca || this.house.rca.length !== 16) {
				message = "РКА дома не найдена";
			} else if (!this.date_begin || !this.date_end || !this.startTime || !this.endTime) {
				message = "Выберите период голосования";
			} else if (!timeFormat.test(this.startTime) || !timeFormat.test(this.endTime)) {
				message = "Время должно быть в формате HH:MM";
			} else if (!this.roomTypeIndex.length) {
				message = "Выберите тип помещения";
			}	else if (this.questions.some(question => !question.description)) {
				message = "Не должно быть пустых вопросов в голосовании";
			}	else if (this.questions.some(question => !question.defaultOptions && question.options.every(option => !option.option))) {
				message = "Не должно быть пустых вариантов ответов";
			} else {
				const startDate = new Date(`${this.date_begin}T${this.startTime}`);
				const endDate = new Date(`${this.date_end}T${this.endTime}`);
				if (startDate >= endDate) {
					message = "Дата и время начала голосования должны быть раньше даты и времени завершения";
				}
			}

			if (message) {
				this.alert = {
					color: "error",
					message,
				};
			}

			return !message;
		},
		async loadServerImage(avatar, indexQ, showDefault = true) {
      if (!avatar && showDefault) {
        this.images.push("@/assets/user.svg");
        return; 
      }

      if (avatar?.id) {
        try {
          const response = await this.$api.ksk.load_file(avatar.id);
          const blob = new Blob([response], { type: 'application/octet-stream' });
          const blobUrl = URL.createObjectURL(blob);
					console.log('this.data.questions', this.data.questions)
					this.questions[indexQ].files.push({url: blobUrl, type: avatar.type, id: avatar.id, original_name: avatar.original_name})
        } catch (error) {
          console.error('Ошибка при загрузке изображения:', error);
          this.images.push("@/assets/user.svg");
        }
      } else {
        this.imageSrc = require("@/assets/user.svg");
      }
    },
		processDate(date, isStart) {
			const processedDate = new Date(typeof date === 'number' ? date * 1000 : date);
			const year = processedDate.getFullYear();
			const month = String(processedDate.getMonth() + 1).padStart(2, '0');
			const day = String(processedDate.getDate()).padStart(2, '0');
			const formattedDate = `${year}-${month}-${day}`;
			const hours = String(processedDate.getHours()).padStart(2, '0');
			const minutes = String(processedDate.getMinutes()).padStart(2, '0');
			const formattedTime = `${hours}:${minutes}`;

			if (isStart) {
				this.date_begin = formattedDate;
				this.startTime = formattedTime;
			} else {
				this.date_end = formattedDate;
				this.endTime = formattedTime;
			}
		},
		async downloadFile(file) {
			const response = await this.$api.ksk.load_file(file.id);
			const blob = new Blob([response], { type: 'application/octet-stream' });
			const blobUrl = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = blobUrl;
			link.download = file.original_name;
			link.click();
			URL.revokeObjectURL(blobUrl);
		},
		formatToUTC(dateString, timeString) {
			const localDateTime = new Date(`${dateString}T${timeString}:00`);
			const utcYear = localDateTime.getUTCFullYear();
			const utcMonth = String(localDateTime.getUTCMonth() + 1).padStart(2, '0');
			const utcDay = String(localDateTime.getUTCDate()).padStart(2, '0');
			const utcHours = String(localDateTime.getUTCHours()).padStart(2, '0');
			const utcMinutes = String(localDateTime.getUTCMinutes()).padStart(2, '0');
			const utcSeconds = String(localDateTime.getUTCSeconds()).padStart(2, '0');

			return `${utcYear}-${utcMonth}-${utcDay} ${utcHours}:${utcMinutes}:${utcSeconds}`;
		}
  },
	beforeMount() {
		console.log('(this.modalData', this.modalData)
		if (Object.keys(this.modalData).length !== 0) {
			this.voteEditId = this.modalData.id
			this.data = JSON.parse(JSON.stringify(this.modalData));
			delete this.data.id
			delete this.data._id
			delete this.data.updated_at
			delete this.data.status
			delete this.data.pretty_id
			delete this.data.created_at
			delete this.data.company
			delete this.data.house
			this.house = this.modalData.house
			if (typeof this.modalData.date_begin === 'string') {
				const date = new Date(this.modalData.date_begin * 1000);
				const year = date.getUTCFullYear();
				const month = String(date.getUTCMonth() + 1).padStart(2, '0');
				const day = String(date.getUTCDate()).padStart(2, '0');
				this.date_begin = `${year}-${month}-${day}`;
				const hours = String(date.getUTCHours()).padStart(2, '0');
				const minutes = String(date.getUTCMinutes()).padStart(2, '0');
				this.startTime = `${hours}:${minutes}`;
			} else if(this.modalData.date_begin) {
				this.processDate(this.modalData.date_begin, true);
			}
			if (typeof this.modalData.date_end === 'string') {
				const date = new Date(this.modalData.date_end * 1000);
				const year = date.getUTCFullYear();
				const month = String(date.getUTCMonth() + 1).padStart(2, '0');
				const day = String(date.getUTCDate()).padStart(2, '0');
				this.date_end = `${year}-${month}-${day}`;
				const hours = String(date.getUTCHours()).padStart(2, '0');
				const minutes = String(date.getUTCMinutes()).padStart(2, '0');
				this.endTime = `${hours}:${minutes}`;
			} else if(this.modalData.date_end) {
				this.processDate(this.modalData.date_end, false);
			}
			if(this.modalData.responsible_voting) {
				this.responsibles = this.modalData.responsible_voting.map(voting => voting.name);
			}
			this.data.room_types = []
			this.modalData.room_types.forEach(e => {
				this.data.room_types.push(e._id)
			});
			this.roomTypeIndex = this.modalData.room_types.map(item => {
				return this.catalog.room_types.findIndex(catalogItem => catalogItem.pretty_id === item.pretty_id);
			});
			if(this.modalData.questions.length) {
				this.questions = []
				for (let i = 0; i < this.modalData.questions.length; i++) {
					this.questionsIndexes[i] = this.modalData.questions[i].id
					let question = this.modalData.questions[i];
					let options = question.options;
					let defaultOptions = false;

					if (options.length === 3 &&
							options.some(opt => opt.option === "За") &&
							options.some(opt => opt.option === "Против") &&
							options.some(opt => opt.option === "Воздержусь")) {
						defaultOptions = true;
						options = [{
							option: ''
						}];
					} else {
						defaultOptions = false;
					}

					this.questions.push({
						id: question.id,
						question_files: question.files,
						files: [],
						description: question.description,
						height: 420 + ((options.length) * 45),
						defaultOptions: defaultOptions,
						options: options
					});
					this.modalData.questions[i].files.forEach(element => {
						this.loadServerImage(element, i)
					});
				}
			}
		}
	},
};
</script>

<style scoped>
.v-text-field {
	border-radius: 6px;
}
.v-card-title {
  border-bottom: 1px solid #ccc;
}
.v-card-text {
  padding-top: 0;
}
.v-divider {
  margin: 20px 0;
}
.v-card-actions {
  padding: 0 16px 16px;
}

.block-info {
	background: #F1F1F1;
	padding: 8px 28px 8px 28px;
}
.block-info span {
	color: #5C6166;
	font-weight: 700;
	font-size: 16px;
}

.block-question {
	width: 100%;
	border: 1px solid #7ED89C;
	padding: 12px 16px 36px 16px;
	border-radius: 15px;
	position: relative;
}

.block-question .desc {
	border-radius: 6px;
}
.disabled-question {
	border: 1px solid #E6E6E6 !important;
}

.add-btn {
	position: absolute;
	bottom: -17px;
	left: 50%;
	transform: translateX(-50%);
	background-color: #fff;
}

.button-add-question {
	display: inline-block;
	border-radius: 19px;
	color: #4CAF50;
	text-decoration: none;
	font-size: 14px;
	height: 38px !important;
	letter-spacing: normal !important;
	padding: 0 12px !important;
}

.remove-question {
	position: absolute;
	background-color: #fff;
	top: -10px;
	right: -10px;
}

.text-dark {
	font-weight: 400;
	color: #000000;
	font-family: 'SegoeUIThis', sans-serif !important;
	font-size: 16px;
}
.notify-image {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.notify-file {
  width: 100%;
  height: 80%;
  border-radius: 4px;
}
.image-wrapper {
  position: relative;
  display: inline-block;
}
.delete-btn {
  display: none;
  position: absolute;
	top: -15px;
	right: -15px;
}

.image-container {
	max-width: 150px;
	height: 113px;
	display: flex;
}
.file-container {
	max-width: 150px;
	height: 85px;
	display: flex;
}

.image-container:hover .delete-btn {
  display: block;
}
.file-container:hover .delete-btn {
  display: block;
}
.gdbrn-info {
	border-radius: 12px !important;
	border: 1px solid rgba(0, 0, 0, 0.22);
}
.gdbrn-info::before {
	box-shadow: none;
}
.amount-rooms {
	font-weight: 700;
	color: #8C9197;
	font-size: 16px;
	flex: none !important;
	margin-right: 10px;
}
.ok-btn-grey {
	color: #8C9197 !important;
}
.modal-body {
  max-height: 80vh;
  overflow-y: auto; 
}
.text-table-center {
	display: block;
	width: 100%;
	text-align: center;
}
</style>

<style>
.no-overflow {
	overflow-y: hidden;
}
.v-input__prepend-outer .v-input__icon--prepend .mdi-paperclip {
	color: #2EB85C;
	transform: rotate(45deg);
}
.file-input .v-input__control .v-input__slot {
	cursor: pointer;
}
.file-input .v-input__control .v-input__slot {
	padding: 0 !important;
}
.view-radio .v-label {
	font-weight: 400;
}
.desc .v-input__slot fieldset {
	border: 1px solid #d8dbe0;
}

.desc input {
	font-size: 14px !important;
}

.modal-header {
	background: #F5F5F5;
	padding: 0px 28px 0px 28px !important;
	border-bottom: 1px solid #E6E6E6;
	height: 45px;
}
.modal-header .title {
	font-size: 16px !important;
	font-weight: 400;
	color: #5C6166;
	font-family: 'SegoeUIThis', sans-serif !important;
}
@font-face {
  font-family: 'SegoeUIThis';
  src: url('/assets/segoeuithis.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'SegoeUIThis';
  src: url('/assets/segoeuithibd.ttf') format('truetype');
  font-weight: bold;
}

.v-dialog .segoeui .v-card,
.v-dialog .segoeui .v-card-title,
.v-dialog .segoeui .v-card-title .title,
.v-dialog .segoeui .v-btn,
.v-dialog .segoeui .v-btn .v-btn__content,
.v-dialog .segoeui .v-icon,
.v-dialog .segoeui .v-chip-group .v-chip .v-chip__content {
  font-family: 'SegoeUIThis', sans-serif !important;
}
.segoeui-lightgrey {
  font-family: 'SegoeUIThis', sans-serif !important;
	color: #8C9197 !important;
}
.segoeui-grey {
  font-family: 'SegoeUIThis', sans-serif !important;
	color: #5C6166 !important;
}
.segoeui-dark {
  font-family: 'SegoeUIThis', sans-serif !important;
	color: #363636 !important;
}
.segoeui {
  font-family: 'SegoeUIThis', sans-serif !important;
}
.ft-16 {
	font-size: 16px !important;
}
.file-name {
	font-size: 12px;
	color: #8C9197 !important;
}
.custom-chip {
	background-color: #E6E6E6;
	opacity: 0.8 !important;
}

.rad .v-label {
	font-size: 14px;
}
.rad .v-icon {
	font-size: 16px;
}
.gbdrn-table .v-data-table__wrapper {
	height: 400px !important;
}
.gdbrn-info .v-expansion-panel-content__wrap {
	padding: 0 !important;
}
.remove-option-icon {
	color: rgba(0, 0, 0, 0.22) !important;
}
.v-text-field input {
	font-family: 'SegoeUIThis', sans-serif !important;
	font-size: 15px;
}
.br-left {
	border-radius: 6px 0px 0px 6px !important;
}
.br-right {
	border-radius: 0px 6px 6px 0px !important;
}
.pointer-none {
	pointer-events: none;
}
.gdbrn-info .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
	color: #363636 !important;
}
.rca .v-input__slot input {
	text-align: center !important;
}
.room .v-input__slot {
	padding-left: 10px !important;
	padding-right: 10px !important;
}
</style>